body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* HEADER */
img { max-width: 100%; }
.navbar-brand {z-index: 9;}
.header_details_inner {color: #fff;display: flex;justify-content: end;flex-wrap: wrap;}
.header_details_user strong, .header_details_user span, .header_details_date strong, .header_details_date span {display: inline-block;width: 100%;}
.header_details_inner .header_details_date .header_details_date_left {width: 28px;margin-right: 16px;} 
.header_details_inner .header_details_date {display: flex;align-items: center;width: 210px;}
.header_details_inner .header_details_user, .header_details_inner .header_details_date {padding-left: 20px;font-size: 13px;}
.header_details_inner .header_details_user {padding-right: 20px;width: 238px;border-right: 1px solid #ccc;}
/* HEADER */


/* STEPS */
.form-label {font-size: 17px;font-weight: 700;line-height: 32px;color: #000;text-transform: capitalize;width: 100%;}
.center_form_step {width: 1200px;max-width: 100%;margin-left: auto;margin-right: auto;padding: 40px; margin-top: 50px;margin-bottom: 50px;
  background: #fff;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;border-radius: 32px;}
.step_checkbox_inner {display: table;margin: 0 auto 40px auto;border-radius: 16px;padding: 22px;text-align: center;position: relative;width: 100%;}
.step_checkbox_inner input[type="radio"] {position: absolute;opacity: 0;}
.step_checkbox_inner span.radio_overly{position:absolute;top:0;bottom:0;left:0;right:0;border:1px solid #ccc;border-radius:16px}
.step_checkbox_inner span.radio_overly:before{content:"";position:absolute;display:inline-block;transform:rotate(45deg);height:9px;width:5px;border-bottom:2px solid #fff;border-right:2px solid #fff;right:17px;top:14px;opacity:0}
span.radio_overly:after{content:"";position:absolute;top:10px;right:10px;width:20px;height:20px;border:1px solid #ccc;border-radius:50%}
.step_checkbox_inner img{height: 60px; margin-bottom: 10px;}
.step_checkbox_inner strong{font-size:20px;line-height:28px}
.step_checkbox_inner span,.step_checkbox_inner strong{display:inline-block;width:100%;text-align:center}

.step_checkbox_inner input[type=checkbox]:checked+.radio_overly::before,.step_checkbox_inner input[type=radio]:checked+.radio_overly::before, .step_checkbox_inner.checked input[type=checkbox]+.radio_overly::before,.step_checkbox_inner.checked input[type=radio]+.radio_overly::before{opacity:1;z-index:9}
.step_checkbox_inner input[type=checkbox]:checked+.radio_overly::after,.step_checkbox_inner input[type=radio]:checked+.radio_overly::after, .step_checkbox_inner.checked input[type=checkbox]+.radio_overly::after,.step_checkbox_inner.checked input[type=radio]+.radio_overly::after {background:#1677ff;border-color:#1677ff}
.step_checkbox_inner input[type=radio]:checked+.radio_overly, .step_checkbox_inner input[type=checkbox]:checked+.radio_overly ,.step_checkbox_inner.checked input[type=radio]+.radio_overly, .step_checkbox_inner.checked input[type=checkbox]+.radio_overly {box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;}
/* END STEPS */


/*TABLE */
.table_data {margin: 0;}
.table_data thead tr th {font-weight: 500;background-color: #f5f7f9;}
.table_data>tbody>tr:hover>*, .td_title {background-color: #f5f7f9 !important; box-shadow : none !important}
.table>:not(caption)>*>* { border-color: #dbe2e9;} 
 .table_data tbody tr td b { font-weight: 500;}
 .link_details_para {font-size: 13px;color: #495057; font-weight: 500;}
 .not_available {position: relative;padding-left: 20px;}
.not_available:before { animation: ping 2000ms cubic-bezier(0, 0, 0.2, 1) infinite;    -webkit-animation: ping 2000ms cubic-bezier(0, 0, 0.2, 1) infinite;}
.not_available:before, .not_available::after {content: ""; position: absolute;width: 12px;height: 12px;left: 0;top: 5px;border-radius: 50%;background: #ff0000;    }

@keyframes ping{
  75%, 100% {
      transform: scale(2);
      opacity: 0;
  }
} 
@-webkit-keyframes ping{
  75%, 100% {
      transform: scale(2);
      opacity: 0;
  }
  
  } 


.not_available.active:before, .not_available.active::after {background: #22c55e;}
/* END TABLE */

@media screen and (max-width: 767px) { 
  .center_form_step {padding: 30px;}
  .header_details_user strong, .header_details_user span, .header_details_date strong, .header_details_date span {font-size: 13px;}
  .header_details_inner .header_details_user, .header_details_inner .header_details_date { padding-left: 15px; padding-right: 15px;}
  .header_details_inner .header_details_date { width: 140px;   margin-left: auto; margin-right: auto; margin-bottom: 20px;}
  .header_details_inner .header_details_user { width: 100%;border: 0;padding-left: 0;padding-right: 0;text-align: center;margin-bottom: 20px;} 
}
@media screen and (min-width: 767px) { 
.header_details {
  margin-top: -39px;
  .header_details_inner .header_details_user {border: 0;}
}
}